import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ee4488e = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _f88af6cc = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _78bf7163 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _adfcf42c = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _700424ce = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _ec28772e = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _974cfdf0 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _11292cdc = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _06a6f7cb = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _3b947a6a = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _0ba281b2 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _08aa4931 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _104a7e12 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _c2df2214 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _3666cbfa = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _17bd6799 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _094f2a93 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _7bd74940 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _99240da4 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _93148e0e = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _3a6772b2 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _88f3c1f0 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _891af8c6 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _43c5bfc6 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _39d858d2 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _6e7648d2 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _a623b252 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _4092e0cc = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _46567a5a = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _09628671 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _456a35c6 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _592c6d68 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _71bde56e = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _8cb03382 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _b1d31bca = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _126a19b1 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _157695d2 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _42e9e6a8 = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _b6d94ff4 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _60884b12 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _23dd3a00 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _674989a7 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _56621714 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _2b9ac902 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _2458aba0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _4ee4488e,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _f88af6cc,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _78bf7163,
    name: "help"
  }, {
    path: "/verify-email",
    component: _adfcf42c,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _700424ce,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _ec28772e,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _974cfdf0,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _11292cdc,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _06a6f7cb,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _3b947a6a,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _0ba281b2,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _08aa4931,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _104a7e12,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _c2df2214,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _3666cbfa,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _17bd6799,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _094f2a93,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _7bd74940,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _99240da4,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _93148e0e,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _3a6772b2,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _88f3c1f0,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _891af8c6,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _43c5bfc6,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _39d858d2,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _6e7648d2,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _a623b252,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _4092e0cc,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _46567a5a,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _09628671,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _456a35c6,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _592c6d68,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _71bde56e,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _8cb03382,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _b1d31bca,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _126a19b1,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _157695d2,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _42e9e6a8,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _b6d94ff4,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _60884b12,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _23dd3a00,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _674989a7,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _56621714,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _2b9ac902,
    name: "blog-slug"
  }, {
    path: "/",
    component: _2458aba0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
